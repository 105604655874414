import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import formbricks from "@formbricks/js/app";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "pipeline",
      component: () => import("@/views/Pipeline.vue"),
      meta: {
        title: "Pipeline",
        requiresAuth: true,
      },
    },

    {
      path: "/omnichannel",
      name: "omnichannel",
      component: () => import("@/views/Omnichannel.vue"),
      meta: {
        title: "Omnichannel",
        active: true,
        requiresAuth: true,
      },
    },

    {
      path: "/calendar",
      name: "calendar",
      component: () => import("@/views/Calendar.vue"),
      meta: {
        title: "Calendário",
        requiresAuth: true,
      },
    },

    {
      path: "/contacts",
      name: "contacts",
      component: () => import("@/views/Contacts.vue"),
      meta: {
        title: "Contacts",
        requiresAuth: true,
      },
    },

    {
      path: "/users",
      name: "users",
      component: () => import("@/views/Users.vue"),
      meta: {
        title: "Users",
        requiresAuth: true,
      },
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        title: "Dashboard",
        requiresAuth: true,
      },
    },

    {
      path: "/planning",
      name: "planning",
      component: () => import("@/views/planning/PlanningHome.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/register",
      name: "planning register",
      component: () => import("@/views/planning/PlanningRegisterStepOne.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/register/steptwo",
      name: "planning register two",
      component: () => import("@/views/planning/PlanningRegisterStepTwo.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/register/stepthree",
      name: "planning register three",
      component: () => import("@/views/planning/PlanningRegisterStepThree.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/register/stepfour",
      name: "planning register four",
      component: () => import("@/views/planning/PlanningRegisterStepFour.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/consult",
      name: "planning consult",
      component: () => import("@/views/planning/PlanningConsult.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/consult/steptwo",
      name: "planning consult two",
      component: () => import("@/views/planning/PlanningConsultTwo.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/consult/stepthree",
      name: "planning consult three",
      component: () => import("@/views/planning/PlanningConsultThree.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/consult/stepfour",
      name: "planning consult four",
      component: () => import("@/views/planning/PlanningConsultFour.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/schedule",
      name: "planning schedule",
      component: () => import("@/views/planning/PlanningSchedule.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/templateManagement",
      name: "planning template",
      component: () => import("@/views/planning/PlanningTemplateManagement"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/planning/pdca",
      name: "PDCA",
      component: () => import("@/views/planning/PlanningRoutine.vue"),
      meta: {
        title: "Planning",
        requiresAuth: true,
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        title: "Entrar",
        layout: "full",
        requiresAuth: false,
      },
    },

    {
      path: "/lists",
      name: "Lists Management",
      component: () => import("@/views/Internal Admin/ListsManagement.vue"),
      meta: {
        title: "Lists",
        requiresAuth: true,
      },
    },

    {
      path: "/pipeline-management",
      name: "Pipeline Management",
      component: () => import("@/views/Internal Admin/PipelineManagement.vue"),
      meta: {
        title: "Pipeline Management",
        requiresAuth: true,
      },
    },

    {
      path: "/workspace-management",
      name: "Workspace Management",
      component: () => import("@/views/Internal Admin/WorkspaceManagement.vue"),
      meta: {
        title: "Workspace Management",
        requiresAuth: true,
      },
    },

    // {
    //   path: "/test",
    //   name: "login",
    //   component: () => import("@/views/dashboard/DashboardRefactor.vue"),
    //   meta: {
    //     title: "Entrar",
    //     layout: "full",
    //     requiresAuth: true,
    //   },
    // },

    {
      path: "/logout",
      name: "logout",
      component: () => {
        store.dispatch("app/logout");
      },
      meta: {
        title: "Contacts",
        requiresAuth: true,
      },
    },

    {
      path: "/password-reset",
      name: "reset",
      component: () => import("@/views/Login.vue"),
      meta: {
        title: "Resetar",
        layout: "full",
        requiresAuth: false,
      },
    },

    {
      path: "/user-invitations/:id/validate",
      name: "auth-invitation",
      component: () => import("@/views/Invitation.vue"),
      meta: {
        title: "Convite",
        layout: "full",
        requiresAuth: false,
      },
    },

    {
      path: "/error/404",
      name: "error404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        requiresAuth: false,
        title: "Erro 404",
        layout: "full",
      },
    },

    {
      path: "*",
      redirect: "error404",
    },
  ],
});

function handleFormBricks(to) {
  if (!window.formbricks && to?.meta?.requiresAuth && typeof window !== "undefined") {
    const user = store.getters["app/getUserData"];

    formbricks.init({
      environmentId: "cm05mn334006pu0h97sxjh6ir",
      apiHost: "https://forms.institutomix.com.br",
      userId: user?.id?.toString(),
    });
  }

  if (typeof window !== "undefined" && to?.meta?.requiresAuth && window.formbricks) {
    formbricks.registerRouteChange();
  }
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
  // Remove initial loading

  // handleFormBricks(to);

  store.commit("app/SET_HAS_ROUTER_NAVIGATION", false);
  store.commit("newsWarning/TICK_UPDATE");

  const appLoading = document.getElementById("loading-screen");

  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  store.commit("app/SET_HAS_ROUTER_NAVIGATION", true);

  const hasToken = !!localStorage.getItem("token");

  store.commit("faqVideos/CHANGE_FAQ_MODAL_CONDITION", false);

  if (window.innerWidth >= 1024) {
    store.commit("navMenu/CHANGE_NAV_MENU_CONDITION", false);
  }

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | CRM`;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (hasToken) {
      next();
    }

    if (!hasToken) {
      next("/login");
    }
  } else {
    if (hasToken) {
      next("/");
    }

    next();
  }
});

export default router;
